@use './assets/styles/fonts';
@use './assets/styles/colors';

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  width: 100%;
  background-color: colors.$color-background;
}

#root {
  min-height: 100%;
}

:root {
  font-family: fonts.$font-regular;
}

* {
  box-sizing: border-box;
}

strong {
  font-family: fonts.$font-medium;
}

