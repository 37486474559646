@use './../../assets/styles/colors';
@use './../../assets/styles/mixins';

.bold {
  font-weight: bold;
  text-transform: uppercase;
}
.Events {
  padding: 40px 0;
  border-bottom: 2px solid colors.$color-background2;
  min-height: 300px;

  &__overview {
    padding: 20px 0;

    &-title {
      padding-bottom: 20px;
      font-size: 2.5rem;
      color: colors.$color-text1;
      text-align: center;
    }
    &-content {
      display: flex;
      justify-content: center;
      align-items: center;

      @include mixins.mobile {
        flex-direction: column;
      }

      &-media {
      }

      &-details {
        padding: 0 20px;
        color: colors.$color-text1;

        &--title {
          font-size: 1.5rem;
          text-transform: uppercase;
          text-decoration: none;
          color: colors.$color-text1;
        }

        &--theme, &--date, &--time, &--address {
          padding-bottom: 5px;
        }
      }
    }
  }
}
