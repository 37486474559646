@mixin mobile-xs {
  @media screen and (max-width: 374px) {
    @content
  }
}

@mixin mobile {
  @media screen and (max-width: 440px) {
    @content
  }
}

@mixin tablet {
  @media (min-width: 767px) and (max-width: 768px) {
    @content
  }
}

@mixin desktop {
  @media screen and (min-width: 768px) {
    @content
  }
}

@mixin laptop {
  @media screen and (min-width: 1150px) {
    @content
  }
}
