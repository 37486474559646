@use './../../assets/styles/colors';
@use './../../assets/styles/fonts';
@use './../../assets/styles/mixins';

.Events-view {
  margin: auto;
  width: 90%;

  &__header {
    &-title {
      font-size: 3em;
      font-weight: bold;
      padding: 20px 0;

      @include mixins.mobile {
        font-size: 20px;
      }
    }
  }

  &__events {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;

    @include mixins.mobile {
      grid-template-columns: repeat(1, 1fr);
    }

    &-item {
      min-height: 230px;
      border: 2px solid colors.$color-background2;
      border-radius: 10px;
      overflow: hidden;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-overlay {
        position: relative;
        height: 200px;

        &--img {
          width: 100%;
          height: 100%;
        }

        &--text {
          background: rgba(0, 0, 0, 0.2);
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;

          &-date {
            margin: 5px;
            padding: 5px;
            box-sizing: content-box;
            background-color: colors.$color-grey-tint;
            color: colors.$color-background;
            text-align: center;
            border-radius: 10px;
            font-weight: bold;
            width: 10%;
          }
        }
      }

      &-content {
        padding: 10px;

        &--title {
          font-size: 18px;
          font-family: fonts.$font-bold;
          color: colors.$color-background2;
        }
      }
    }
  }
}
