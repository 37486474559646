$color-white: rgb(255, 255, 255);

$color-background: rgb(241, 232, 233);
$color-background2: rgb(40, 53, 147);
$color-background3: rgb(244, 33, 144);
$color-background4: rgb(192, 33, 87);
$light-background: rgba(216, 32, 80, 0.1);

$color-text1: rgb(35, 31, 32);
$color-grey-tint: rgba(54, 56, 64, 0.7);

$color-flat-grey1: rgb(54, 56, 64);
$color-flat-grey2: rgb(4, 4, 4);
$color-flat-grey3: rgb(149, 149, 149);
$color-flat-grey4: rgb(236, 236, 237);
$color-flat-grey5: rgb(79, 77, 85);

$color-dark-grey: rgb(10, 9, 11);
$color-box-shadow: rgba(10, 9, 11, 0.07);

$color-blue: rgb(23, 81, 208);
$color-red: rgba(255, 78, 78);

$color-brand-disabled: rgba(12, 10, 9, 0.8);

$color-primary-green1: rgb(19, 255, 128);

