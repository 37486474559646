@use './../../assets/styles/colors';
@use './../../assets/styles/fonts';
@use './../../assets/styles/mixins';

.Homepage {

  @include mixins.mobile {
    background: transparent;
  }

  &__hero {
    padding-top: 20px;
    display: flex;
    height: calc(100vh - 190px);
    background: linear-gradient(to right, transparent 50%, colors.$color-background2 50%);

    @include mixins.mobile {
      width: 100%;
      padding-top: 0;
      background: transparent;
    }

    &-content {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-self: center;
      padding: 0 35px;

      @include mixins.mobile {
        width: 100%;
      }

      &--title {
        font-size: 4rem;
        text-transform: capitalize;
        color: colors.$color-text1;
        font-family: fonts.$font-bold;

        @include mixins.mobile {
          font-size: 2rem;
        }

        @include mixins.tablet {
          font-size: 2rem;
        }
      }

      &--title {
        padding: 10px 0;
        color: colors.$color-text1;
      }

      &--sub-text {
        padding-bottom: 15px;
      }

      &--action {
        margin-top: 20px;

        @include mixins.mobile {
          display: flex;
          flex-direction: column;
        }

        &-donate, &-about {
          padding: 15px 20px;
          border-radius: 5px;
          background-color: colors.$color-background3;
          border: 1px solid colors.$color-background3;
          color: colors.$color-background;
          text-decoration: none;
          margin-right: 20px;

          @include mixins.mobile {
            margin-bottom: 10px;
          }
        }

        &-about {
          background-color: transparent;
          border: 1px solid colors.$color-text1;
          color: colors.$color-text1;
        }
      }
    }

    &-media {
      width: 50%;
      display: flex;
      align-items: flex-end;

      @include mixins.mobile {
        display: none;
      }

      &-img {
        @include mixins.tablet {
          width: -webkit-fill-available;
        }
      }
    }
  }

  &__blog {
    margin: auto;
    width: 85%;
    padding: 30px 0;

    &-title {
      font-size: 3rem;
      text-transform: capitalize;
      color: colors.$color-text1;
      width: 40%;
      padding: 20px 0;
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;

      &-card {
        height: 200px;
        width: 200px;
        border: 1px solid;
      }
    }
  }

  &__info {
    margin-top: 20px;
    color: colors.$color-background;
    height: 400px;
    background: linear-gradient(
      135deg,
      colors.$color-background4 12.5%,
      colors.$color-background3 12.5%,
      colors.$color-background3 25%,
      colors.$color-background2 25%,
      colors.$color-background2 75%,
      colors.$color-background3 75%,
      colors.$color-background3 87.5%,
      colors.$color-background4 87.5%,
    );

    &_flex{
      font-size: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      @include mixins.mobile {
        font-size: 1.5rem;
      }

      &-box {
        margin: 0 auto;
        width: 45%;
      }

      &-sign {
        font-size: 4rem;
        font-family: cursive;
        text-transform: capitalize;

        @include mixins.mobile {
          font-size: 1.5rem;
          padding: 0 2px;
        }
      }
    }
  }
}
